/* About Section */
.about-section {
    position: relative;
    padding: 4rem 1rem;
    /* Adjust padding for smaller screens */
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.5rem;
    align-items: center;
}

.image-col {
    grid-column: span 6;
}

.text-col {
    grid-column: span 6;
}

/* Image Styling */
.image-wrapper {
    margin-right: 2rem;
    position: relative;
}

.profile-image {
    border-radius: 50%;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14rem;
    height: 14rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Experience Content */
.experience-content {
    text-align: center;
}

.experience-years {
    color: #196c68;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0;
    display: block;
}

.counter-value {
    font-size: 3.75rem;
    font-weight: 600;
}

.experience-text {
    font-weight: 600;
    margin-top: 0.5rem;
    display: block;
}

/* Text Section Styling */
.text-wrapper {
    margin-left: 2rem;
}

.section-title {
    color: #14b8a6;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.section-heading {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.section-description {
    color: #5c5e5f;
    max-width: 40rem;
    margin-bottom: 1.5rem;
}

/* Button Styling */
.read-more-btn {
    height: 2.5rem;
    padding: 0 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    border-radius: 0.375rem;
    background-color: #14b8a6;
    color: white;
    text-decoration: none;
}

.read-more-btn:hover {
    background-color: #0d9488;
}

.read-more-btn i {
    margin-left: 0.25rem;
}

#btnAbout {
    /* Default button styling */
    padding: 10px 25px;
    background-color: #196C68;
    /* Initial background color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Initial box-shadow */
    border: none;
    /* No border */
    border-radius: 5px;
    /* Rounded corners */
    color: white;
    /* Initial text color */
    cursor: pointer;
    /* Pointer cursor on hover */
    position: relative;
    /* Positioning context for pseudo-elements */
    overflow: hidden;
    /* Hide overflow of pseudo-elements */
    font-size: 15px;
    /* Font size */
    font-weight: 500;
    /* Font weight */
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    /* Smooth transition */
}

#btnAbout::before,
#btnAbout::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    transition: transform 0.3s ease;
    z-index: 0;
    /* Ensure pseudo-elements are behind text */
}

#btnAbout::before {
    left: 0;
    background-color: #3477c0;
    /* Background color for sliding effect */
    transform: translateX(-100%);
}

#btnAbout::after {
    right: 0;
    background-color: #3477c0;
    /* Background color for sliding effect */
    transform: translateX(100%);
}

#btnAbout:hover::before,
#btnAbout:hover::after {
    transform: translateX(0);
    /* Slide effect on hover */
}

#btnAbout:hover {
    background-color: #196C68;
    /* Change background color on hover */
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    /* Hover effect for box-shadow */
    color: white;
}

#btnAbout span {
    position: relative;
    /* Relative position for stacking context */
    z-index: 1;
    /* Ensure text stays above pseudo-elements */
    transition: color 0.3s ease;
    /* Smooth transition for text color */
}

/* Ensure the button text remains visible and centered */
#btnAbout {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Media Queries for Responsive Design */
@media (max-width: 1024px) {
    .grid-container {
        grid-template-columns: 1fr;
        /* Stack columns on top of each other */
        text-align: center;
    }

    .image-wrapper {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .text-wrapper {
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .experience-overlay {
        width: 12rem;
        height: 12rem;
    }

    .experience-years {
        font-size: 1.25rem;
    }

    .counter-value {
        font-size: 3rem;
    }
}

@media (max-width: 550px) {
    .grid-container {
        flex-direction: column-reverse;
    }

    .profile-image {
        width: 100%;
        height: auto;
        margin: 0;
    }

    .text-col {
        grid-column: span 12;
        padding: 0;
    }

    .image-col {
        grid-column: span 12;
        padding: 0;
    }

    .experience-overlay {
        width: 10rem;
        height: 10rem;
    }

    .experience-years {
        font-size: 1rem;
    }

    .counter-value {
        font-size: 2.5rem;
    }

    .section-heading {
        font-size: 1.25rem;
    }

    .section-description {
        font-size: 0.875rem;
    }

    #btnAbout {
        padding: 6px 20px;
        font-size: 14px;
    }
}