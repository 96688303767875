
.service2-section-areas {
    background-size: cover;
    background-repeat: no-repeat;
    background: #EFF7F6;
    position: relative;
}


span.maximum {
    border-radius: 4px;
    background: rgba(241, 190, 20, 0.10);
    padding: 10px 16px;
    font-weight: 400;
}

.service-after-before {
    margin-bottom: 30px;
    height: 400px;
}

.service-after-before {
    position: relative;
    z-index: 1;
    padding: 28px 24px;
    transition: all .4s ease-in-out;
}

.service-after-before a {
    color: #196C68;
}


.service-after-before:hover a {
    color: white;
    position: relative;
    z-index: 2;
}

.service-after-before:hover ul li {
    color: white;
    position: relative;
    z-index: 2;
}


.service-after-before::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(../images/servicebg.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    transition: all .4s ease-in-out;
    border-radius: 5px;
}

.service2-icon {
    position: relative;
    z-index: 3;
}

.service2-icon {
    background: #E8EDF1;
    height: 70px;
    display: inline-block;
    width: 70px;
    text-align: center;
    line-height: 70px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.service-after-before p h3 {
    color: #196C68;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3)
}

.service-after-before h1 {
    position: relative;
    z-index: 3;
    transition: all .4s ease-in-out;
}

.service-after-before h3 {
    color: #196C68;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}

.service-after-before:hover h3 {
    color: white;
    position: relative;
    z-index: 3;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}

.service-after-before p {
    position: relative;
    z-index: 3;
    transition: all .4s ease-in-out;
}

.service-after-before a.learn-more {
    position: relative;
    z-index: 3;
    transition: all .4s ease-in-out;
}

a.learn-more span {
    transition: all .4s ease-in-out;
}

.service-after-before::after {
    border-radius: 5px;
    background: #FFF;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    padding: 28px 24px;
    top: 0;
    z-index: -1;
    transition: all .4s ease-in-out;
}

.service-after-before:hover::after {
    border-radius: 5px;
    opacity: 0.88;
    background: #141B2C;
    z-index: 1;
    transition: all .4s ease-in-out;
}

.service-after-before::after {
    border-radius: 5px;
    background: #FFF;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    padding: 28px 24px;
    top: 0;
    z-index: -1;
    transition: all .4s ease-in-out;
}

.service-after-before:hover h1 {
    color: #fff;
    transition: all .4s ease-in-out;
}

.service-after-before:hover p {
    color: #fff;
    transition: all .4s ease-in-out;
}

.service-after-before:hover .service2-icon {
    position: relative;
    z-index: 3;
    background: #fff;
    transition: all .4s ease-in-out;
}

.listStyle {
    list-style: circle;
    text-align: initial;
    margin-bottom: 10px;
    margin-left: 30px;
    color: #56605F;
}

.service-after-before:hover ul li {
    color: white;
}

@media (max-width:1200px) {
    .service-after-before{
        height: 450px;
    }    

}
@media (max-width:768px) {
    .listStyle{
        margin-top: 25px;
    }
    .service-after-before{
        height: 325px;
    }
}

@media (max-width:420px) {
    .service-haeder-text h1 {
        padding: 0px;
    }
    .service-after-before{
        height: 375px;
    }
}