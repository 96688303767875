/* General Styling */
.service11-section-area {
    background: #F6F6F6;
    position: relative;
}

.section-padding8 {
    padding: 60px 0 50px;
}

.margin-b60 {
    margin-bottom: 60px;
}

.aroplan3 {
    border-radius: 5px;
    background: rgba(241, 190, 20, 0.10);
    padding: 6px 10px;
    margin-bottom: 24px;
}

/* Responsive header title */
.header-title {
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
    color: #196C68;
}

/* Service Box Area */
.service11-boxarea {
    margin-bottom: 30px;
    position: relative;
}

.service11-img {
    overflow: hidden;
    transition: all .4s ease-in-out;
    border-radius: 5px;
}

.service11-img img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: all .4s ease-in-out;
}

.service11-boxtext {
    background: #fff;
    padding: 50px 20px 24px 20px;
    position: relative;
    border-radius: 4px;
    width: 90%;
    text-align: center;
    margin: 0 auto;
    top: -30px;
    z-index: 1;
    transition: all .4s ease-in-out;
}

.service-vectorarea {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 2;
    border-radius: 70px;
    background: #22BFCA;
    display: inline-block;
    height: 70px;
    width: 70px;
    transition: all .4s ease-in-out;
    line-height: 60px;
}

.service-vectorarea img {
    filter: brightness(0) invert(1);
}

.service11-boxarea:hover .service11-img img {
    transform: scale(1.1);
    transition: all .4s ease-in-out;
}

.service11-boxarea:hover .service-vectorarea {
    transition: all .1s ease-in-out;
    background-color: rgba(22, 218, 182, 0.901);
}

.service11-boxarea:hover .service11-boxtext::after {
    height: 100%;
    transition: all .4s ease-in-out;
    background-color: #196C68;
}

.service11-boxarea:hover {
    color: white;    
}

.service11-boxarea:hover .service12box{
    background-color: #196C68;
}

/* Button Styling */
#btnKnow {
    padding: 7px 25px;
    background-color: #196C68;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    font-size: 15px;
    font-weight: 500;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    width: 200px;
    height: 40px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

#btnKnow::before,
#btnKnow::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    transition: transform 0.3s ease;
    z-index: 0;
}

#btnKnow::before {
    left: 0;
    background-color: #3477c0;
    transform: translateX(-100%);
}

#btnKnow::after {
    right: 0;
    background-color: #3477c0;
    transform: translateX(100%);
}

#btnKnow:hover::before,
#btnKnow:hover::after {
    transform: translateX(0);
}

#btnKnow:hover {
    background-color: #3477c0;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.2);
    color: white;
}

#btnKnow span {
    z-index: 1;
}
@media (max-width:1200px) {
    .service-vectorarea {
        display: none;
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .service11-boxarea {
        margin-bottom: 20px;
    }
    .service11-boxtext {
        padding: 30px 10px;
        top: -20px;
    }
    .service-vectorarea {
        height: 50px;
        width: 50px;
        line-height: 40px;
        top: 38%;
    }
    .service11-boxtext h5 {
        font-size: 18px;
    }
    .service11-boxtext p {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .header-title {
        font-size: 28px;
        line-height: 36px;
    }
    .service11-boxtext {
        padding: 20px 5px;
        top: -15px;
    }
    .service-vectorarea {
        height: 40px;
        width: 40px;
        line-height: 30px;
        top: 35%;
    }
    .service11-boxtext h5 {
        font-size: 16px;
    }
    .service11-boxtext p {
        font-size: 12px;
    }
}
