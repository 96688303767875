.servicebenefites11-section-area {
  background: #F6F6F6;
  position: relative;
}

.margin-b24 {
  margin-bottom: 24px;
}

.weight-500 {
  font-weight: 500 !important;
}

.lineh-16 {
  line-height: 16px;
}

.aropaln3 {
  border-radius: 5px;
  background: rgba(241, 190, 20, 0.10);
  padding: 6px 10px;
  margin-bottom: 24px;
  color: black;
  font-weight: 400;
  padding: 10px 20px;
}

.themebtn11.thmbtn12 {
  background: #49357F;
}

a.themebtn11 {
  border-radius: 4px;
  background: #22BFCA;
  padding: 18px 24px;
}

.themebtn11 {
  border: 0 solid #22BFCA;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all .4s ease-in-out;
}


#btnGuide {
  /* Default button styling */
  padding: 14px 25px;
  background-color: #196C68;
  /* Initial background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Initial box-shadow */
  border-radius: 5px;
  /* Rounded corners */
  color: white;
  /* Initial text color */
  cursor: pointer;
  /* Pointer cursor on hover */
  position: relative;
  /* Positioning context for pseudo-elements */
  overflow: hidden;
  /* Hide overflow of pseudo-elements */
  font-size: 15px;
  /* Font size */
  font-weight: 500;
  /* Font weight */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  /* Smooth transition */
  text-decoration: none;
}

#btnGuide::before,
#btnGuide::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  transition: transform 0.3s ease;
  z-index: 0;
  /* Ensure pseudo-elements are behind text */
}

#btnGuide::before {
  left: 0;
  background-color: #3477c0;
  /* Background color for sliding effect */
  transform: translateX(-100%);
}

#btnGuide::after {
  right: 0;
  background-color: #3477c0;
  /* Background color for sliding effect */
  transform: translateX(100%);
}

#btnGuide:hover::before,
#btnGuide:hover::after {
  transform: translateX(0);
  /* Slide effect on hover */
}

#btnGuide:hover {
  background-color: #3477c0;
  /* Change background color on hover */
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  /* Hover effect for box-shadow */
  color: white;
}

#btnGuide span {
  position: relative;
  /* Relative position for stacking context */
  z-index: 1;
  /* Ensure text stays above pseudo-elements */
  transition: color 0.3s ease;
  /* Smooth transition for text color */
}

/* Ensure the button text remains visible and centered */
#btnGuide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.immigrationarea {
  margin-bottom: 16px;
  width: 100%;
}

.immigritation-listarea{
  padding: 0 0 0 70px;
}

.ts{
  transition: all .4s ease-in-out;
}

.immigratation2 span{
  color: #ffff;
}

.immigrationarea span{
  height: 32px;
  width: 32px;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  margin: 0 14px 0 0;
  transition: all .4s ease-in-out;
  color: #1EA5AF;
}

.immigrationarea{
  background: #196C68;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: all .4s ease-in-out;
}

.immigratation2:hover{
  background: #49357F;
}
.immigrationarea:hover{
  background: #3477c0;
  transition: all .4s ease-in-out;
  transform: translateY(-5px);
}


.immigrationarea{
  margin-bottom: 16px;
  width: 100%;
}

@media (max-width:990px) {
  .immigritation-listarea{
    margin-top: 25px;
  }
}


@media (max-width:550px) {
  .immigritation-listarea{
    padding: 0px;
  }
  #giveTop{
    margin-top: 50px;
  }
  #valueBox{
    margin-top: 50px;
  }
}