.navbar-brand {
  font-size: 20px;
  font-family: 'Trebuchet MS', sans-serif;
}

#imgLogo {
  max-width: 170px;
  max-height: 85px;
}

#myCont {
  display: flex;
  color: black;
  justify-content: center;
}

#NavId {
  z-index: 3;
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  padding-top: 25x;
  padding-bottom: 25px;
}

@media (max-width:480px) {
  #imgLogo {
      width: 150px;
      height: 70px;
  }

  #name {
      width: 260px;
  }

  #email {
      width: 260px;
  }

  #phone {
      width: 260px;
  }

  #requirement {
      width: 260px;
  }

  .paraText {
      text-align: center;
      margin-left: 0px;
  }

  #rowReverse {
      display: flex;
      flex-direction: column-reverse;
  }

  .abou-5textarea5 {
      padding: 0px 0px 0px 5px;
  }

  img.elementor-9 {
      display: none;
  }

  .about5-images {
      margin-top: 3%;
  }
}

@media (max-width:990px) {
  #myCont {
      display: none;
  }

  #navLogo {
      justify-content: center;
  }
}

/* Navbar Section Styles */
.navbar-section {
  position: sticky;
  top: 0;
  z-index: 4;
  /* background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.5), rgba(64, 64, 64, 0.5), rgba(105, 105, 105, 0.5), rgba(169, 169, 169, 0.5), rgba(211, 211, 211, 0.5)); */
  /* backdrop-filter: blur(20px); */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  padding: 2px;
  transition: background 0.3s ease-in-out;
}

/* Sticky Navbar Styles */
.sticky {
  background: white;
  opacity: .9;
  backdrop-filter: none;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15); */
}

.navbar-nav .nav-link {
  color: black;
}

.navbar-nav .nav-link:hover {
  color: white;
}

.Nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.Nav.scrolled {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-link {
  color: white;
  transition: color 0.3s ease;
}

.Nav.scrolled .nav-link {
  color: black;
}