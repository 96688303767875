.price-list ul li{
    padding-bottom: 16px;
    list-style: none;
}

.ts{
    transition: all .4s ease-in-out;
}

.price-list ul li span{
    margin: 0 10px 0 0;
}

.price-list ul li span{
    border-radius: 160px;
    background: #196C68;
    color: #fff;
    height: 20px;
    width: 20px;
    display: inline-block;
    line-height: 20px;
    text-align: center;
    font-size: 13px;
}

.company-images img{
    width: 100%;
    height: 100%;
}
@media (max-width:980px) {
    .company-images{
        display: none;
    }
}